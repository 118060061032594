import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button } from "@material-tailwind/react";
import rainImage from '../assets/images/rain.png'
import ReactWhatsapp from 'react-whatsapp';
import { FaWhatsappSquare } from "react-icons/fa";
import {
    PhoneIcon,
    EnvelopeIcon
  } from "@heroicons/react/24/solid";
import { Link } from 'react-router-dom'

const Rain = () => {
 
    return (
    <div className='h-screen w-full bg-gradient-to-r from-cyan-500 to-blue-500'> 
    <Card className="center w-80 lg:w-96  mx-auto">
    <CardHeader floated={false} className="h-64 lg:h-80">
      <img src={rainImage} alt="profile-picture" />
    </CardHeader>
    <CardBody className="text-center">
      <Typography variant="h4" color="blue-gray" className="mb-2">
        Rain Ng Zhi Yang
      </Typography>
      <Typography color="blue-gray" className="font-medium" textGradient>
        Managing Director
      </Typography>
    </CardBody>
    <div className='mx-auto pb-8'>
    <div className='pb-4 flex'>
        <PhoneIcon color="#2196f3" className="h-6 w-6 mr-4"/><Typography color='black'>9185 8930 / 9006 0730</Typography>
        </div>
        <div className='pb-4 flex'>
        <EnvelopeIcon color="#2196f3" className="h-6 w-6 mr-4"/><Typography color='black'><a href="mailto:events@rainsolutions.org">events@rainsolutions.org</a></Typography>
        </div>
        <div className='flex'>
        <ReactWhatsapp number="+6591858930" message="Hey there, I'm [Name] here and I just viewed your Rain Solutions Website!"><FaWhatsappSquare color="#2196f3" className="cursor-pointer h-6 w-6 mr-4"/></ReactWhatsapp><ReactWhatsapp number="+6591858930" message="Hey there, I'm [Name] here and I just viewed your Rain Solutions Website!"><Typography className="cursor-pointer" color='black'>Shortcut to WhatsApp</Typography></ReactWhatsapp>
        </div>
    </div>
    <div className='mx-auto pb-8'>
    <Link to="/" target='_blank'><Button to="registration" color="blue" className='p-4' size="lg">COMPANY WEBSITE</Button></Link>
    </div>
  </Card>
  </div>
  )
}

export default Rain
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import Error from './pages/Error';
import Rain from './components/Rain';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route exact path='/' element={< Home />}></Route>
      <Route exact path='/rain' element={< Rain />}></Route>
      <Route exact path='*' element={< Error />}></Route>
    </Routes>
  </Router>
);
import React from 'react'
import { Typography } from "@material-tailwind/react"; 

const Portfolio = () => {

  return (
    <div id="portfolio" className='text-center pt-32 bg-white mx-auto'>
    <Typography variant='h1' className='text-center text-black'>PORTFOLIO</Typography>
    <Typography variant='lead' className='w-11/12 lg:w-9/12 py-24 mx-auto'>At Rain Solutions, our success lies in both the caliber of our workforce and the innovation of our systems. Our well-oiled team ensures a plain sailing execution of processes, marked by attention to detail. What truly sets us apart is our exclusive event management systems, developed in-house through 20 years of experience. These accomodating yet reliable systems streamline processes and provide real-time insights, offering a perfect blend of top-tier manpower and bespoke technology. We aim to form a holistic and seamless experience for all our clients. If you need real time updates, require a substantial outreach to your participants or a way to handle their details, <span className='font-bold'>just leave it to us.</span> </Typography>
    </div>
  )
}

export default Portfolio
import React, { useState, useEffect} from 'react'
import { Link } from 'react-scroll'
import { Link as Linkto } from 'react-router-dom'
import Logo from '../assets/images/RS.png'
import { 
    Button, 
    Typography,   
    List,
    ListItem,
    ListItemPrefix, } from "@material-tailwind/react"; 
import './Navbar.css'
import {
    InformationCircleIcon,
    WrenchScrewdriverIcon,
    ListBulletIcon,
  } from "@heroicons/react/24/solid";

const Navbar = () => {

    const [isOpen, setIsopen] = useState(false);
    const [top, setTop] = useState(false)

    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    }

    const [navBackground, setNavBackground] = useState('ease-in duration-300 bg-white w-full fixed z-0 top-0 left-0')
    const navRef = React.useRef()
    navRef.current = navBackground
    useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 90
            if (show) {
                setNavBackground('ease-in duration-300 bg-white w-full fixed z-0 top-0 left-0')
                setTop(true)
            } else {
                setNavBackground('ease-in duration-300 bg-white w-full fixed z-0 top-0 left-0')
                setTop(false)
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
    <div className='z-50 header'>
        <nav ref={navRef} className={navBackground}>
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4 pl-4 lg:p-4">
        <Link spy={true} offset={-55} smooth={true} duration={1000} to="hero" className="cursor-pointer flex items-center">
            <img src={Logo} className="h-10 lg:h-12" alt="Rain Solutions" />
        </Link>
        <div className="flex order-2">
            <Link spy={true} offset={-80} smooth={true} duration={1000} to="contact"><Button to="registration" color="blue" className='p-4' size="lg">CONTACT US</Button></Link>
            <Button onClick={ToggleSidebar} data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center text-white bg-transparent lg:hidden hover:shadow-none shadow-none" aria-controls="navbar-sticky" aria-expanded="false">
                 <svg className="w-6 h-6" aria-hidden="true" fill="#525252" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"></path></svg>
            </Button>
        </div>
        <div className="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1" id="navbar-sticky">
            <ul className="flex flex-col p-4 lg:p-0 mt-4 font-medium rounded-lg lg:flex-row lg:space-x-8 lg:mt-0">
            <li className='text-xl cursor-pointer'>
            </li>
            <li className='text-xl cursor-pointer'>
                <Link activeClass="text-xl md:text-blue-400" spy={true} offset={-80} smooth={true} duration={1000} to="services" className="flex text-neutral-600 py-2 pl-3 pr-4 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-400 md:p-0"><WrenchScrewdriverIcon className="h-6 w-6 mr-2"/><Typography  className='text-xl'>Services</Typography></Link>
            </li>
            <li className='text-xl cursor-pointer'>
                <Link activeClass="text-xl md:text-blue-400" spy={true} offset={-80} smooth={true} duration={1000} to="portfolio" className="flex text-xl text-neutral-600 py-2 pl-3 pr-4 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-400 md:p-0"><ListBulletIcon className="h-7 w-7 mr-2"/><Typography className='text-xl'>Portfolio</Typography></Link>
            </li>
            </ul>
        </div>
        </div>
        </nav>
        <div className={`sidebar ${isOpen === true ? 'active' : ''}`} onClick={ToggleSidebar}>
                <div className="sd-body">
                    <ul>
                        <li><Link spy={true} smooth={true} offset={-80} duration={1000} onClick={ToggleSidebar} to="services" className="sttelemedia underline sd-link text-4xl">Services</Link></li>
                        <li><Link spy={true} smooth={true} offset={-80} duration={1000} onClick={ToggleSidebar} to="portfolio" className="sttelemedia underline sd-link text-4xl">Portfolio</Link></li>
                        <li><Link spy={true} smooth={true} offset={-80} duration={1000} onClick={ToggleSidebar} to="contact" className="sttelemedia underline sd-link text-4xl">Contact Us</Link></li>
                    </ul>
                </div>
            </div>
    </div>
  )
}

export default Navbar

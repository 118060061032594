import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import React from "react";
import Portfolio from "../components/Portfolio";
import Contact from "../components/Contact";
import Services from "../components/Services";
import About from "../components/About";
import Clientele from "../components/Clientele";
import {useEffect} from 'react';
import ScrollToTop from "react-scroll-to-top";
import HowItWorks from "../components/HowItWorks";
import EventsGallery from "../components/EventsGallery";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


   return (
    <>
      <Navbar/>
      <Hero/>
      <Services/>
      <Portfolio/>
      <HowItWorks/>
      <Clientele/>
      <EventsGallery/>
      <Contact/>
      <Footer/>
      <ScrollToTop smooth color="blue" width="40"/>
    </>
   )
};
 
export default Home;
import React, {useState} from 'react'
import { Typography, Stepper, Step, Button, Card } from "@material-tailwind/react"; 
import {
  CheckCircleIcon,
  GiftIcon,
  EnvelopeIcon,
  DocumentTextIcon,
  CreditCardIcon
} from "@heroicons/react/24/solid";
import { Fade } from 'react-awesome-reveal';
import backgroundVideo from '../assets/videos/video1.mp4'
import { useSwipeable } from 'react-swipeable';

const HowItWorks = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [isLastStep, setIsLastStep] = React.useState(false);
    const [isFirstStep, setIsFirstStep] = React.useState(false);
   
    const handleNext = () => {
      !isLastStep && setActiveStep((cur) => cur + 1)
      localStorage.setItem('active', (cur) => cur + 1)
    };
    const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

    const handlers = useSwipeable({
        trackMouse: true,
        onSwipedLeft: () => handleNext(),
        onSwipedRight: () => handlePrev()
      });

    const DisplayData = () => {
      if (activeStep === 0) {
        return (
          <Card {...handlers} className='prevent-select h-96 mt-8 text-center'>
            <div className=' my-auto'>
            <Typography variant='h1' className='text-4xl lg:text-5xl text-center mb-4'>Microsite</Typography>
            <Typography className="lg:text-lg w-10/12 mx-auto" variant='paragraph'>Let us know your event's theme for the microsite as well as its content. From there, we will design and create components and elements that fit and personify a professional, dynamic and responsive microsite for all devices. This microsite could also be used as an online registration platform from which we can gather potential participants' information and much more.</Typography>
            </div>
          </Card>
        )
      } else if (activeStep === 1) {
        return (
          <Card {...handlers} className='prevent-select h-96 mt-8 text-center'>
               <div className=' my-auto'>
            <Typography variant='h1' className='text-4xl lg:text-5xl text-center mb-4'>Email Blast</Typography>
            <Typography className="w-10/12 mx-auto" variant='paragraph'>With the data gathered (or a simple datasheet provided by you), we are able to generate and mass send unique and personalised emails. This way, you can engage with your participants at a more intimate and profound level while providing them with crucial and necessary information about your event. At the same time, we will provide them with their own unique QR Code for on-site registration.</Typography>
            </div>
          </Card>
        )
      } else if (activeStep === 2) {
        return (
          <Card {...handlers} className='prevent-select h-96 mt-8 text-center'>
            <div className=' my-auto'>
            <Typography variant='h1' className='text-4xl lg:text-5xl text-center mb-4'>On-site Regisration</Typography>
            <Typography className="w-10/12 mx-auto" variant='paragraph'>With the unique QR code provided, participants are able to scan them at our on-site registration counters. Our system is efficient and streamlines the registration process, avoiding long wait times and clearing queues swiftly. Should anyone have any difficulties or issues, our friendly manpower will be there and ready to assist them.</Typography>
            </div>
          </Card>
        )
      } else if (activeStep === 3) {
        return (
          <Card {...handlers} className='prevent-select h-96 mt-8 text-center'>
            <div className=' my-auto'>
            <Typography variant='h1' className='text-4xl lg:text-5xl text-center mb-4'>Lucky Draw</Typography>
            <Typography className="w-10/12 mx-auto" variant='paragraph'>We offer an exciting way to keep your participants engaged. Making use of our lucky draw system provides you an opportunity to distribute prizes fairly and with suspense. Through a predraw where with one click, a representative can draw up to hundreds of winners randomly and a live stage draw where a representative can draw a sequence of individual prizes on stage with a remote device. </Typography>
            </div>
          </Card>
        )
      } else if (activeStep === 4) {
        return (
          <Card {...handlers} className='prevent-select h-96 mt-8 text-center'>
           <div className=' my-auto'>
            <Typography variant='h1' className='text-4xl lg:text-5xl text-center mb-4'>Event Status Updates</Typography>
            <Typography className="w-10/12 mx-auto" variant='paragraph'>Throughout and at end the event, we are able to provide you with a list of key information, such at attendees, absentees, winners, time of arrival, department and much more. You never need to be worry about the lack of information before, during or after the event, and you can always enquire about it with our team at any time.</Typography>
            </div>
          </Card>
        )
      }
  
    }
  return (
    <div>
            <div dangerouslySetInnerHTML={{ __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${backgroundVideo}"
        />,
  ` }}></div>
    <div className='content2 p-10'>
    <Typography variant='h1' className='text-center text-white'>HOW IT WORKS</Typography>
      {DisplayData()}
       <div className="w-full py-4 px-8">
      <Stepper
        className='z-0 my-4'
        activeStep={activeStep}
        isLastStep={(value) => setIsLastStep(value)}
        isFirstStep={(value) => setIsFirstStep(value)}
      >
        <Step onClick={() => setActiveStep(0)}><CreditCardIcon className='h-6'/></Step>
        <Step onClick={() => setActiveStep(1)}><EnvelopeIcon className='h-6'/></Step>
        <Step onClick={() => setActiveStep(2)}><CheckCircleIcon className='h-6'/></Step>
        <Step onClick={() => setActiveStep(3)}><GiftIcon className='h-6'/></Step>
        <Step onClick={() => setActiveStep(4)}><DocumentTextIcon className='h-6'/></Step>
      </Stepper>
      </div>
      <div className="flex justify-between">
        <Button size='lg' color='blue' onClick={handlePrev} disabled={isFirstStep}>
          Prev
        </Button>
        <Button size='lg' color='blue' onClick={handleNext} disabled={isLastStep}>
          Next
        </Button>
      </div>
    </div>
    </div>
  )
}

export default HowItWorks

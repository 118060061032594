import { Typography, Input, Textarea, Button } from '@material-tailwind/react'
import React, {useRef, useState} from 'react'
import {
  PhoneIcon,
  EnvelopeIcon
} from "@heroicons/react/24/solid";
import { Slide } from 'react-awesome-reveal';
import Rain from '../assets/images/rain.png'
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactWhatsapp from 'react-whatsapp';
import { FaWhatsappSquare } from "react-icons/fa";

const Contact = () => {
  
  const form = useRef();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNo: "",
    message: ""
  })

  const sendEmail = () => {
    try {   
      if (formData.name === "" || formData.email === "" || formData.phoneNo === "" || formData.message === "") {
        toast.error('Please enter your details and submit again.', {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "light",
          })
      return
      } else {
        emailjs.sendForm('service_dq7nbhr', 'template_lqhtjl9', form.current, 'r04-bQl4Di3WPya_p')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        window.scrollTo(0, 0)
        setFormData({   
          name: "",
        email: "",
        phoneNo: "",
        message: ""})
      }

  } catch(e) {
      toast.error('Email was not succesfully sent, please try again.', {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "light",
          })
      return
    }
    toast.success("Email succesfully sent, you'll hear back from us shortly.", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "light",
      })
  return
  }



  return (
    <div id='contact' className='py-32 bg-white'>
      <ToastContainer/>
      <Typography variant='h1' className='pb-10 text-center'>CONTACT US</Typography>
      <div className='max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2'>
      <div className='col-span-1 px-10 py-20 lg:py-40 lg:px-20'>
      <Typography variant="h2" className='text-center'>
        Say Hi!
      </Typography>
      <Typography color='gray' className='py-4 text-center'>
        Whether you have questions or you would just like to say hello, contact us.
        </Typography>
        <form ref={form} autoComplete="off" className="mt-8 mb-2 mx-auto">
        <div className="mb-1 flex flex-col gap-6"> 
          <Input onInput={(e) => setFormData({...formData, name: e.target.value})} value={formData.name} name="name" label='Name'/>
          <Input onInput={(e) => setFormData({...formData, email: e.target.value})} value={formData.email} name="email" label="Email"/>
          <Input onInput={(e) => setFormData({...formData, phoneNo: e.target.value})} value={formData.phoneNo} name="phoneNo" label="Contact No"/>
          <Textarea onInput={(e) => setFormData({...formData, message: e.target.value})} value={formData.message} name="message" label="Message" />
        </div>
        <Button onClick={sendEmail} onClickcolor="blue" className="mt-6" fullWidth>
          Submit
        </Button>
      </form>
      </div>
      <Slide triggerOnce duration={1500} direction='right'>
      <div className='col-span-1 bg-gray-900 rounded-2xl mt-32 m-1 px-10 py-20 lg:py-44 lg:px-20'>
      <Typography variant="h3" color="white">
        Contact Information
      </Typography>
      <Typography className="py-4" color="gray">
      Fill up the form and our team will get back to you within 24 hours.
        </Typography>
        <div className='py-4 flex'>
        <PhoneIcon color="white" className="h-6 w-6 mr-4"/><Typography color='white'>9185 8930 / 9006 0730</Typography>
        </div>
        <div className='pb-4 flex'>
        <EnvelopeIcon color="white" className="h-6 w-6 mr-4"/><Typography color='white'>events@rainsolutions.org</Typography>
        </div>
        <div className='pb-4 flex'>
        <ReactWhatsapp number="+6591858930" message="Hey there, I'm [Name] here and I just viewed your Rain Solutions Website!"><FaWhatsappSquare color="white" className="cursor-pointer h-6 w-6 mr-4"/></ReactWhatsapp><ReactWhatsapp number="+6590060730" message="Hey there, I'm [Name] here and I just viewed your Rain Solutions Website!"><Typography className="cursor-pointer" color='green'>Shortcut to WhatsApp</Typography></ReactWhatsapp>
        </div>
      </div>
      </Slide>
      </div>
    </div>
  )
}

export default Contact

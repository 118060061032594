import { Carousel, Typography } from "@material-tailwind/react";
import Photo1 from "../assets/images/1.jpg"
import Photo2 from "../assets/images/2.jpg"
import Photo3 from "../assets/images/3.jpg"
import Photo4 from "../assets/images/4.jpg"
import Photo5 from "../assets/images/5.jpg"
import Photo6 from "../assets/images/6.jpg"
import Photo7 from "../assets/images/7.jpg"
import Photo8 from "../assets/images/8.jpg"
import Photo9 from "../assets/images/9.jpg"
import Photo10 from "../assets/images/10.jpg"

const EventsGallery = () => {
    return (
        <div className="w-full px-4 pt-32 pb-32 bg-gray-100">
            <Typography variant='h1' className='text-center px-10 pt-10 pb-4'>GALLERY</Typography>
<Carousel loop={true} autoplay={true} className="pictureheight max-w-3xl mx-auto rounded-xl">
      <img
        src={Photo1}
        alt="image 1"
        className="h-full w-full object-cover object-center"
      />
      <img
        src={Photo3}
        alt="image 3"
        className="h-full w-full object-cover object-center"
      />
            <img
        src={Photo4}
        alt="image 4"
        className="h-full w-full object-cover object-center"
      />
      <img
        src={Photo5}
        alt="image 5"
        className="h-full w-full object-cover object-center"
      />
      <img
        src={Photo6}
        alt="image 6"
        className="h-full w-full object-cover object-center"
      />
            <img
        src={Photo7}
        alt="image 7"
        className="h-full w-full object-cover object-center"
      />
      <img
        src={Photo8}
        alt="image 8"
        className="h-full w-full object-cover object-center"
      />
            <img
        src={Photo2}
        alt="image 2"
        className="h-full w-full object-cover object-center"
      />
      <img
        src={Photo10}
        alt="image 10"
        className="h-full w-full object-cover object-center"
      />
    </Carousel>
      </div>
    )
}

export default EventsGallery
import React from 'react'
import './Hero.css'
import backgroundVideo from '../assets/videos/video1.mp4'
import eventbg from '../assets/images/eventbg.jpg'
import { Typography } from '@material-tailwind/react'
import {Fade} from 'react-awesome-reveal'


const Hero = () => {
  return (
    <div className='' id='hero'>
        {/*<div dangerouslySetInnerHTML={{ __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${backgroundVideo}"
        />,
  ` }}></div>*/}
  <img src={eventbg} className='heroImg'/>
      <div className='content p-10'>
        <Fade triggerOnce duration={2000}>
        <p className='customFont font-semibold text-white text-5xl md:text-6xl lg:text-7xl text-center'>
          Dependable systems, powered by reliable hands
        </p>
        </Fade>
        </div>
    </div>
  )
}

export default Hero
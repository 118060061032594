import React from 'react'
import AIA from "../assets/images/aia.png"
import Allianz from "../assets/images/Allianz.png"
import Amazon from "../assets/images/amazon.png"
import Centurion from '../assets/images/centurion.png'
import CocaCola from "../assets/images/cocacola.png"
import ExxonMobil from "../assets/images/exxonmobil.png"
import Keppel from "../assets/images/keppel.png"
import Makino from "../assets/images/makino.png"
import OCBC from "../assets/images/ocbc.png"
import Reitas from "../assets/images/reitas.png"
import STT from "../assets/images/sttgdc.png"
import Shimadzu from "../assets/images/shimadzu.png"
import Teleflex from "../assets/images/teleflex.png"
import rmg from "../assets/images/rmg.png"
import GreatEastern from "../assets/images/great eastern.png"
import ntuc from "../assets/images/ntuc_income-removebg-preview.png"
import sncf from "../assets/images/SNCF_s_current_logo-removebg-preview.png"
import vopak from "../assets/images/vopak.png"
import burgerking from "../assets/images/burgerking.png"
import singlife from "../assets/images/singlife.png"
import orangetee from "../assets/images/orange_tee-removebg-preview.png"
import ych from "../assets/images/ych-partner.png"
import sana from "../assets/images/sana-removebg-preview.png"
import { Typography } from '@material-tailwind/react'
import { Fade } from 'react-awesome-reveal'

const Clientele = () => {
    const images = [AIA, Allianz, Amazon, CocaCola, ExxonMobil, Keppel, OCBC, STT, Shimadzu, Teleflex, sana, ych]
    const images2 = [rmg, Centurion, Makino, Reitas, ntuc, vopak, sncf, GreatEastern, burgerking, singlife, orangetee,]
  return (
    <div className='pt-32 pb-32 bg-white'>
    <Typography variant='h1' className='text-center px-10 pt-10 pb-4'>PAST CLIENTELE</Typography>
    <Fade triggerOnce duration={1000}>
    <div className="w-[200%] flex h-20 border-t border-b border-gray-600 relative">
        <div className="w-[200%] flex items-center h-20 justify-around absolute left-0 animate">
            {images.map((i) => {
            return (
                <div className="flex justify-center items-start w-[20rem]">
                <img src={i} className='imgtest max-h-14 pl-5 md:pl-10 lg:pl-20'/>
                </div>
            );
            })}

{images.map((i) => {
            return (
                <div className="flex justify-center items-start w-[20rem]">
                <img src={i} className='imgtest max-h-14 pl-5 md:pl-10 lg:pl-20'/>
                </div>
            );
            })}
            
        </div>
    </div>
    <div className="mt-8 w-[200%] flex h-20 border-t border-b border-gray-600 relative">
        <div className="w-[200%] flex items-center h-20 justify-around absolute left-0 animate2">
            {images2.map((i) => {
            return (
                <div className="flex justify-center items-start w-[20rem]">
                <img src={i} className='imgtest max-h-14 pl-5 md:pl-10 lg:pl-20'/>
                </div>
            );
            })}

{images2.map((i) => {
            return (
                <div className="flex justify-center items-start w-[20rem]">
                <img src={i} className='imgtest max-h-14 pl-5 md:pl-10 lg:pl-20'/>
                </div>
            );
            })}
            
        </div>
    </div>
    </Fade>
    </div>
  )
}

export default Clientele